import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Flex from '../components/Flex'
import Image from '../components/Image'

const propTypes = {
  data: PropTypes.object,
}
const defaultProps = {}

const DataProtection = ({ data }) => (
  <Layout line>
    <SEO title="Datenschutz" />
    <Flex smallplus sx={{ flexDirection: `column` }}>
      <h1 sx={{ hyphens: `manual` }}>Daten&shy;schutz&shy;erkl&auml;&shy;rung</h1>
    </Flex>
    <Flex
      large
      sx={{
        p: 0,
        height: [`240px`, `320px`, `400px`, `400px`],
      }}
    >
      <Image fluid={data.protection.childImageSharp.gatsbyImageData} />
    </Flex>
    <Flex sx={{ flexDirection: `column` }}>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
        personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten
        sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche
        Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h3>Datenerfassung auf unserer Website</h3>
      <p>
        <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
      </p>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen
        Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
      </p>
      <p>
        <strong>Wie erfassen wir Ihre Daten?</strong>
      </p>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
        sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
        sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
        betreten.
      </p>
      <p>
        <strong>Wofür nutzen wir Ihre Daten?</strong>
      </p>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
        gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
      </p>
      <p>
        <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
      </p>
      <p>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
        Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
        Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
        Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
        Aufsichtsbehörde zu.
      </p>
      <h3>Analyse-Tools und Tools von Drittanbietern</h3>
      <p>
        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das
        geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres
        Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
        zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die
        Nichtbenutzung bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in
        der folgenden Datenschutzerklärung.
      </p>
      <p>
        Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie
        in dieser Datenschutzerklärung informieren.
      </p>
      <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
        behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
        Datenschutzvorschriften sowie dieser Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
        nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
        E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
        durch Dritte ist nicht möglich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
      <address>
        4fb GmbH
        <br />
        Nordring 82B
        <br />
        63067 Offenbach am Main
        <br />
        Deutschland
      </address>
      <p>
        Telefon: +49 69 801082-0
        <br />
        E-Mail: info@4fb.de
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam
        mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B.
        Namen, E-Mail-Adressen o. Ä.) entscheidet.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
        können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
        Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
      <p>
        Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der
        zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen
        Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
        seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können
        folgendem Link entnommen werden:{' '}
        <a
          /* eslint-disable-next-line max-len */
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
          rel="noopener noreferrer"
        >
          BFDI
        </a>
        .
      </p>
      <h3>Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
        Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
        maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
        an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
        ist.
      </p>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
        senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
        daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem
        Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
        übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Verschlüsselter Zahlungsverkehr auf dieser Website</h3>
      <p>
        Besteht nach dem Abschluss eines kostenpflichtigen Vertrags eine Verpflichtung, uns Ihre
        Zahlungsdaten (z.B. Kontonummer bei Einzugsermächtigung) zu übermitteln, werden diese Daten
        zur Zahlungsabwicklung benötigt.
      </p>
      <p>
        Der Zahlungsverkehr über die gängigen Zahlungsmittel (Visa/MasterCard, Lastschriftverfahren)
        erfolgt ausschließlich über eine verschlüsselte SSL- bzw. TLS-Verbindung. Eine
        verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
        &quot;http://&quot; auf &quot;https://&quot; wechselt und an dem Schloss-Symbol in Ihrer
        Browserzeile.
      </p>
      <p>
        Bei verschlüsselter Kommunikation können Ihre Zahlungsdaten, die Sie an uns übermitteln,
        nicht von Dritten mitgelesen werden.
      </p>
      <h3>Auskunft, Sperrung, Löschung</h3>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
        Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
        oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten
        können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
      </p>
      <h3>Widerspruch gegen Werbe-Mails</h3>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
        Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird
        hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
        Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <h2>3. Datenschutzbeauftragter</h2>
      <h3>Gesetzlich vorgeschriebener Datenschutzbeauftragter</h3>
      <p>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</p>
      <address>
        Thomas Sch&auml;fer-Tertilt
        <br />
        4fb GmbH
        <br />
        Nordring 82B
        <br />
        63067 Offenbach am Main
        <br />
        Deutschland
      </address>
      <p>
        Telefon: +49 69 801082-0
        <br />
        E-Mail: dataprotection@zwimlo.com
      </p>
      <h2>4. Datenerfassung auf unserer Website</h2>
      <h3>Cookies</h3>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem
        Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
        nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die
        auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
        Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden
        nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät
        gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
        werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
        oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
        Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
        eingeschränkt sein.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur
        Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion)
        erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B.
        Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser
        Datenschutzerklärung gesondert behandelt.
      </p>
      <h3>Server-Log-Dateien</h3>
      <p>
        Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
        Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
      </p>
      <ul>
        <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li> <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>
      </ul>
      <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>
      <p>
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von
        Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </p>
      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
        Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
        Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
        nicht ohne Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich
        auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
        Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
        unberührt.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur
        Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die
        Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende
        gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h3>Registrierung auf dieser Website</h3>
      <p>
        Sie können sich auf unserer Website registrieren, um zusätzliche Funktionen auf der Seite zu
        nutzen. Die dazu eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen
        Angebotes oder Dienstes, für den Sie sich registriert haben. Die bei der Registrierung
        abgefragten Pflichtangaben müssen vollständig angegeben werden. Anderenfalls werden wir die
        Registrierung ablehnen.
      </p>
      <p>
        Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen
        nutzen wir die bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
        informieren.
      </p>
      <p>
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
        Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <p>
        Die bei der Registrierung erfassten Daten werden von uns gespeichert, solange Sie auf
        unserer Website registriert sind und werden anschließend gelöscht. Gesetzliche
        Aufbewahrungsfristen bleiben unberührt.
      </p>
      <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
      <p>
        Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die
        Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich
        sind (Bestandsdaten). Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die
        Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
        gestattet. Personenbezogene Daten über die Inanspruchnahme unserer Internetseiten
        (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um
        dem Nutzer die Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen.
      </p>
      <p>
        Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der
        Geschäftsbeziehung gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt.
      </p>
      <h3>Datenübermittlung bei Vertragsschluss für Dienstleistungen und digitale Inhalte</h3>
      <p>
        Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der
        Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte
        Kreditinstitut.
      </p>
      <p>
        Eine weitergehende Übermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
        Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe Ihrer Daten an Dritte ohne
        ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
      </p>
      <p>
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von
        Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
      </p>
      <h2>5. Soziale Medien</h2>
      <h3>Inhalte teilen über Plugins (Facebook, Google+1, Twitter & Co.)</h3>
      <p>
        Die Inhalte auf unseren Seiten können datenschutzkonform in sozialen Netzwerken wie
        Facebook, Twitter oder Google+ geteilt werden. Diese Seite nutzt dafür das{' '}
        <a
          href="https://www.e-recht24.de/erecht24-safe-sharing.html#datenschutz"
          rel="noopener noreferrer"
        >
          eRecht24 Safe Sharing Tool
        </a>
        . Dieses Tool stellt den direkten Kontakt zwischen den Netzwerken und Nutzern erst dann her,
        wenn der Nutzer aktiv auf einen dieser Button klickt.
      </p>
      <p>
        Eine automatische Übertragung von Nutzerdaten an die Betreiber dieser Plattformen erfolgt
        durch dieses Tool nicht. Ist der Nutzer bei einem der sozialen Netzwerke angemeldet,
        erscheint bei der Nutzung der Social-Buttons von Facebook, Google+1, Twitter & Co. ein
        Informations-Fenster, in dem der Nutzer den Text vor dem Absenden bestätigen kann.
      </p>
      <p>
        Unsere Nutzer können die Inhalte dieser Seite datenschutzkonform in sozialen Netzwerken
        teilen, ohne dass komplette Surf-Profile durch die Betreiber der Netzwerke erstellt werden.
      </p>
      <h3>Facebook-Plugins (Like & Share-Button)</h3>
      <p>
        Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1
        Hacker Way, Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie
        an dem Facebook-Logo oder dem &quot;Like-Button&quot; (&quot;Gefällt
        {/* eslint-disable-next-line max-len */}
        mir&quot;) auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier:{' '}
        <a href="https://developers.facebook.com/docs/plugins/" rel="noopener noreferrer">
          Developers Facebook
        </a>
        .
      </p>
      <p>
        Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem
        Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass
        Sie mit Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
        &quot;Like-Button&quot; anklicken während Sie in Ihrem Facebook-Account eingeloggt sind,
        können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann
        Facebook den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
        wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
        Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie in der
        Datenschutzerklärung von Facebook unter:{' '}
        <a href="https://de-de.facebook.com/policy.php" rel="noopener noreferrer">
          Facebook Policy
        </a>
        .
      </p>
      <p>
        Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto
        zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
      </p>
      <h3>Twitter Plugin</h3>
      <p>
        Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden
        angeboten durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103,
        USA. Durch das Benutzen von Twitter und der Funktion &quot;Re-Tweet&quot; werden die von
        Ihnen besuchten Websites mit Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt
        gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir als
        Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
        durch Twitter erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung
        von Twitter unter:{' '}
        <a href="https://twitter.com/privacy" rel="noopener noreferrer">
          Twitter Privacy
        </a>
        .
      </p>
      <p>
        {/* eslint-disable-next-line max-len */}
        Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen unter{' '}
        <a href="https://twitter.com/account/settings" rel="noopener noreferrer">
          Twitter Settings
        </a>
        ändern.
      </p>
      <h3>Google+ Plugin</h3>
      <p>
        Unsere Seiten nutzen Funktionen von Google+. Anbieter ist die Google Inc., 1600 Amphitheatre
        Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        Erfassung und Weitergabe von Informationen: Mithilfe der Google+-Schaltfläche können Sie
        Informationen weltweit veröffentlichen. Über die Google+-Schaltfläche erhalten Sie und
        andere Nutzer personalisierte Inhalte von Google und unseren Partnern. Google speichert
        sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch Informationen
        über die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise
        zusammen mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in
        Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen auf Websites und
        Anzeigen im Internet eingeblendet werden.
      </p>
      <p>
        Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie
        und andere zu verbessern. Um die Google+-Schaltfläche verwenden zu können, benötigen Sie ein
        weltweit sichtbares, öffentliches Google-Profil, das zumindest den für das Profil gewählten
        Namen enthalten muss. Dieser Name wird in allen Google-Diensten verwendet. In manchen Fällen
        kann dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über
        Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann Nutzern angezeigt
        werden, die Ihre E-Mail-Adresse kennen oder über andere identifizierende Informationen von
        Ihnen verfügen.
      </p>
      <p>
        Verwendung der erfassten Informationen: Neben den oben erläuterten Verwendungszwecken werden
        die von Ihnen bereitgestellten Informationen gemäß den geltenden
        Google-Datenschutzbestimmungen genutzt. Google veröffentlicht möglicherweise
        zusammengefasste Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer
        und Partner weiter, wie etwa Publisher, Inserenten oder verbundene Websites.
      </p>
      <h3>Instagram Plugin</h3>
      <p>
        Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen
        werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA 94025, USA
        integriert.
      </p>
      <p>
        Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie durch Anklicken des
        Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch
        kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
        hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten
        sowie deren Nutzung durch Instagram erhalten.
      </p>
      <p>
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:{' '}
        <a href="https://instagram.com/about/legal/privacy/" rel="noopener noreferrer">
          Instagram Privacy
        </a>
        .
      </p>
      <h3>LinkedIn Plugin</h3>
      <p>
        Unsere Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die LinkedIn
        Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA.
      </p>
      <p>
        Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn enthält, wird eine
        Verbindung zu Servern von LinkedIn aufgebaut. LinkedIn wird darüber informiert, dass Sie
        unsere Internetseiten mit Ihrer IP-Adresse besucht haben. Wenn Sie den
        &quot;Recommend-Button&quot; von LinkedIn anklicken und in Ihrem Account bei LinkedIn
        eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf unserer Internetseite Ihnen und
        Ihrem Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten
        keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch LinkedIn haben.
      </p>
      <p>
        {/* eslint-disable-next-line max-len */}
        Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter:{' '}
        <a href="https://www.linkedin.com/legal/privacy-policy" rel="noopener noreferrer">
          LinkedIn Privacy
        </a>
        .
      </p>
      <h3>XING Plugin</h3>
      <p>
        Unsere Website nutzt Funktionen des Netzwerks XING. Anbieter ist die XING AG, Dammtorstraße
        29-32, 20354 Hamburg, Deutschland.
      </p>
      <p>
        Bei jedem Abruf einer unserer Seiten, die Funktionen von XING enthält, wird eine Verbindung
        zu Servern von XING hergestellt. Eine Speicherung von personenbezogenen Daten erfolgt dabei
        nach unserer Kenntnis nicht. Insbesondere werden keine IP-Adressen gespeichert oder das
        Nutzungsverhalten ausgewertet.
      </p>
      <p>
        Weitere Information zum Datenschutz und dem XING Share-Button finden Sie in der
        Datenschutzerklärung von XING unter:{' '}
        <a href="https://www.xing.com/app/share?op=data_protection" rel="noopener noreferrer">
          Xing Data Protection
        </a>
        .
      </p>
      <h2>6. Analyse Tools und Werbung</h2>
      <h3>Google Analytics</h3>
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die
        Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        Google Analytics verwendet so genannte &quot;Cookies&quot;. Das sind Textdateien, die auf
        Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
        ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website
        werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p>
        Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.
      </p>
      <p>
        <strong>IP Anonymisierung</strong>
      </p>
      <p>
        Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre
        IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in
        die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google
        diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die
        im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt.
      </p>
      <p>
        <strong>Browser Plugin</strong>
      </p>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
        Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
        können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre
        Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
        Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
        verfügbare Browser-Plugin herunterladen und installieren:{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer">
          Google GA-Opt-Out
        </a>
        .
      </p>
      <p>
        <strong>Widerspruch gegen Datenerfassung</strong>
      </p>
      <p>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf
        folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten
        bei zukünftigen Besuchen dieser Website verhindert:
        <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
        Datenschutzerklärung von Google:{' '}
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          rel="noopener noreferrer"
        >
          Google Support
        </a>
        .
      </p>
      <p>
        <strong>Auftragsdatenverarbeitung</strong>
      </p>
      <p>
        Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen
        die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics
        vollständig um.
      </p>
      <p>
        <strong>Demografische Merkmale bei Google Analytics</strong>
      </p>
      <p>
        Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch
        können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der
        Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google
        sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person
        zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in
        Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im
        Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
      </p>
      <h3>Google Analytics Remarketing</h3>
      <p>
        Unsere Websites nutzen die Funktionen von Google Analytics Remarketing in Verbindung mit den
        geräteübergreifenden Funktionen von Google AdWords und Google DoubleClick. Anbieter ist die
        Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        Diese Funktion ermöglicht es die mit Google Analytics Remarketing erstellten
        Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von Google AdWords und Google
        DoubleClick zu verknüpfen. Auf diese Weise können interessenbezogene, personalisierte
        Werbebotschaften, die in Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
        Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem anderen Ihrer Endgeräte (z.B.
        Tablet oder PC) angezeigt werden.
      </p>
      <p>
        Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu diesem Zweck Ihren
        Web- und App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise können auf jedem
        Endgerät auf dem Sie sich mit Ihrem Google-Konto anmelden, dieselben personalisierten
        Werbebotschaften geschaltet werden.
      </p>
      <p>
        Zur Unterstützung dieser Funktion erfasst Google Analytics google-authentifizierte IDs der
        Nutzer, die vorübergehend mit unseren Google-Analytics-Daten verknüpft werden, um
        Zielgruppen für die geräteübergreifende Anzeigenwerbung zu definieren und zu erstellen.
      </p>
      <p>
        Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft widersprechen, indem Sie
        personalisierte Werbung in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link:{' '}
        <a href="https://www.google.com/settings/ads/onweb/">Google Ads Settings</a>
      </p>
      <p>
        Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt ausschließlich auf
        Grundlage Ihrer Einwilligung, die Sie bei Google abgeben oder widerrufen können (Art. 6 Abs.
        1 lit. a DSGVO). Bei Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto
        zusammengeführt werden (z.B. weil Sie kein Google-Konto haben oder der Zusammenführung
        widersprochen haben) beruht die Erfassung der Daten auf Art. 6 Abs. 1 lit. f DSGVO. Das
        berechtigte Interesse ergibt sich daraus, dass der Websitebetreiber ein Interesse an der
        anonymisierten Analyse der Websitebesucher zu Werbezwecken hat.
      </p>
      <p>
        Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der
        Datenschutzerklärung von Google unter:{' '}
        <a href="https://www.google.com/policies/technologies/ads/">Google Ads Policy</a>
      </p>
      <h3>Google AdWords und Google Conversion-Tracking</h3>
      <p>
        Diese Website verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm der Google
        Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).
      </p>
      <p>
        Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf
        eine von Google geschaltete Anzeige klicken wird ein Cookie für das Conversion-Tracking
        gesetzt. Bei Cookies handelt es sich um kleine Textdateien, die der Internet-Browser auf dem
        Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und
        dienen nicht der persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte
        Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können Google und wir
        erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet
        wurde.
      </p>
      <p>
        Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die
        Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies
        eingeholten Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden zu
        erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die
        Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem
        Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine
        Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am
        Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie
        des Google Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen leicht
        deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.
      </p>
      <p>
        Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
        DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.
      </p>
      <p>
        Mehr Informationen zu Google AdWords und Google Conversion-Tracking finden Sie in den
        Datenschutzbestimmungen von Google:{' '}
        <a href="https://www.google.de/policies/privacy/">Google Privacy</a>
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert
        werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle
        oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des
        Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
        eingeschränkt sein.
      </p>
      <h3>Google reCAPTCHA</h3>
      <p>
        Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist
        die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
      </p>
      <p>
        Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in einem
        Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt.
        Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener
        Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher die Website betritt.
        Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer
        des Websitebesuchers auf der Website oder vom Nutzer getätigte Mausbewegungen). Die bei der
        Analyse erfassten Daten werden an Google weitergeleitet.
      </p>
      <p>
        Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht
        darauf hingewiesen, dass eine Analyse stattfindet.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse daran, seine Webangebote vor
        missbräuchlicher automatisierter Ausspähung und vor SPAM zu schützen.
      </p>
      <p>
        Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google
        entnehmen Sie folgenden Links:{' '}
        <a href="https://www.google.com/intl/de/policies/privacy/">Google Privacy</a>
        und <a href="https://www.google.com/recaptcha/intro/android.html">Google ReCaptcha</a>
      </p>
      <h3>Facebook Pixel</h3>
      <p>
        Unsere Website nutzt zur Konversionsmessung das Besucheraktions-Pixel von Facebook, Facebook
        Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA (“Facebook”).
      </p>
      <p>
        So kann das Verhalten der Seitenbesucher nachverfolgt werden, nachdem diese durch Klick auf
        eine Facebook-Werbeanzeige auf die Website des Anbieters weitergeleitet wurden. Dadurch
        können die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
        ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.
      </p>
      <p>
        Die erhobenen Daten sind für uns als Betreiber dieser Website anonym, wir können keine
        Rückschlüsse auf die Identität der Nutzer ziehen. Die Daten werden aber von Facebook
        gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist
        und Facebook die Daten für eigene Werbezwecke, entsprechend der{' '}
        <a href="https://www.facebook.com/about/privacy/">Facebook-Datenverwendungsrichtlinie</a>
        verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook
        sowie außerhalb von Facebook ermöglichen. Diese Verwendung der Daten kann von uns als
        Seitenbetreiber nicht beeinflusst werden.
      </p>
      <p>
        In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer
        Privatsphäre: <a href="https://www.facebook.com/about/privacy/">Facebook Privacy</a>
      </p>
      <p>
        Sie können außerdem die Remarketing-Funktion “Custom Audiences” im Bereich Einstellungen für
        Werbeanzeigen unter{' '}
        <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">
          Facebook Ad-Settings
        </a>
        deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
      </p>
      <p>
        Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte Werbung von Facebook auf
        der Website der European Interactive Digital Advertising Alliance deaktivieren:{' '}
        <a href="http://www.youronlinechoices.com/de/praferenzmanagement/">Your Online Choices</a>
      </p>
      <h2>7. Newsletter</h2>
      <h3>Newsletterdaten</h3>
      <p>
        Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von
        Ihnen eine E-Mail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass
        Sie der Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des Newsletters
        einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben.
        Diese Daten verwenden wir ausschließlich für den Versand der angeforderten Informationen und
        geben diese nicht an Dritte weiter.
      </p>
      <p>
        Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen Daten erfolgt
        ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte
        Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand
        des Newsletters können Sie jederzeit widerrufen, etwa über den &quot;Austragen&quot;-Link im
        Newsletter. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom
        Widerruf unberührt.
      </p>
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
        zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters
        gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für
        den Mitgliederbereich) bleiben hiervon unberührt.
      </p>
      <h3>MailChimp</h3>
      <p>
        Diese Website nutzt die Dienste von MailChimp für den Versand von Newslettern. Anbieter ist
        die Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA.
      </p>
      <p>
        MailChimp ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und
        analysiert werden kann. Wenn Sie Daten zum Zwecke des Newsletterbezugs eingeben (z.B.
        E-Mail-Adresse), werden diese auf den Servern von MailChimp in den USA gespeichert.
      </p>
      <p>
        MailChimp verfügt über eine Zertifizierung nach dem “EU-US-Privacy-Shield”. Der
        “Privacy-Shield” ist ein Übereinkommen zwischen der Europäischen Union (EU) und den USA, das
        die Einhaltung europäischer Datenschutzstandards in den USA gewährleisten soll.
      </p>
      <p>
        Mit Hilfe von MailChimp können wir unsere Newsletterkampagnen analysieren. Wenn Sie eine mit
        MailChimp versandte E-Mail öffnen, verbindet sich eine in der E-Mail enthaltene Datei (sog.
        web-beacon) mit den Servern von MailChimp in den USA. So kann festgestellt werden, ob eine
        Newsletter-Nachricht geöffnet und welche Links ggf. angeklickt wurden. Außerdem werden
        technische Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und
        Betriebssystem). Diese Informationen können nicht dem jeweiligen Newsletter-Empfänger
        zugeordnet werden. Sie dienen ausschließlich der statistischen Analyse von
        Newsletterkampagnen. Die Ergebnisse dieser Analysen können genutzt werden, um künftige
        Newsletter besser an die Interessen der Empfänger anzupassen.
      </p>
      <p>
        Wenn Sie keine Analyse durch MailChimp wollen, müssen Sie den Newsletter abbestellen.
        Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung.
        Des Weiteren können Sie den Newsletter auch direkt auf der Website abbestellen.
      </p>
      <p>
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO).
        Sie können diese Einwilligung jederzeit widerrufen, indem Sie den Newsletter abbestellen.
        Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
        unberührt.
      </p>
      <p>
        Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis
        zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters
        sowohl von unseren Servern als auch von den Servern von MailChimp gelöscht. Daten, die zu
        anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den Mitgliederbereich)
        bleiben hiervon unberührt.
      </p>
      <p>
        Näheres entnehmen Sie den Datenschutzbestimmungen von MailChimp unter:{' '}
        <a href="https://mailchimp.com/legal/terms/" target="_blank" rel="noopener noreferrer">
          MailChimp Legal
        </a>
        .
      </p>
      <p>
        <strong>Abschluss eines Data-Processing-Agreements</strong>
      </p>
      <p>
        Wir haben ein sog. „Data-Processing-Agreement“ mit MailChimp abgeschlossen, in dem wir
        MailChimp verpflichten, die Daten unserer Kunden zu schützen und sie nicht an Dritte
        weiterzugeben. Dieser Vertrag kann unter folgendem Link eingesehen werden:{' '}
        <a
          href="https://mailchimp.com/legal/forms/data-processing-agreement/sample-agreement/"
          rel="noopener noreferrer"
        >
          MailChimp “Privacy
        </a>
        .
      </p>
      <h2>8. Plugins und Tools</h2>
      <h3>YouTube</h3>
      <p>
        Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten
        ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.
      </p>
      <p>
        Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine
        Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt,
        welche unserer Seiten Sie besucht haben.
      </p>
      <p>
        Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr
        Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem
        Sie sich aus Ihrem YouTube-Account ausloggen.
      </p>
      <p>
        Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer
        Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
        DSGVO dar.
      </p>
      <p>
        Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von
        YouTube unter:{' '}
        <a href="https://www.google.de/intl/de/policies/privacy" rel="noopener noreferrer">
          Google Privacy
        </a>
        .
      </p>
      <h3>Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die
        von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten
        Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google
        aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere
        Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer
        einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer
        genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
        <a href="https://developers.google.com/fonts/faq" rel="noopener noreferrer">
          Google Fonts
        </a>
        und in der Datenschutzerklärung von Google:{' '}
        <a href="https://www.google.com/policies/privacy/" rel="noopener noreferrer">
          Google Privacy
        </a>
        .
      </p>
      <h3>Google Maps</h3>
      <p>
        Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc.,
        1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      </p>
      <p>
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern.
        Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und
        dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
      </p>
      <p>
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer
        Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen
        Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von
        Google:{' '}
        <a href="https://www.google.de/intl/de/policies/privacy/" rel="noopener noreferrer">
          Google Privacy
        </a>
        .
      </p>
    </Flex>
  </Layout>
)

DataProtection.propTypes = propTypes
DataProtection.defaultProps = defaultProps

export default DataProtection

export const query = graphql`
  {
    protection: file(relativePath: { eq: "rav/ansgar-scheffold-0lmvV2AFVyQ-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`
